/* stylelint-disable */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* stylelint-enable */

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400&family=Open+Sans:ital,wght@0,300;0,400;1,300&display=swap");

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #012a4a;
  color: #f9f9f9;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}
